#popupModal {
    &.in {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .modal-dialog {
        width: fit-content;
        margin: auto;
        .popup-modal {
            max-height: 506px;
            max-width: inherit !important;
            display: flex;
            flex-wrap: wrap;
            width: 703px;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            background-color: #fff;
            border: none;
            margin: 0 !important;
            .close-modal {
                position: absolute;
                top: 1rem;
                inset-inline-start: 1rem;
                padding: 0;
                margin: 0;
                background: none;
                border: none;
                &::after {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36.77' height='36.77' viewBox='0 0 36.77 36.77'%3E%3Cg id='Group_17083' data-name='Group 17083' transform='translate(-611.056 214.649) rotate(-45)'%3E%3Cpath id='Path_29513' data-name='Path 29513' d='M2905.785,305.984h17.561' transform='translate(-2331)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='1.5'/%3E%3Cpath id='Path_29514' data-name='Path 29514' d='M2905.785,305.984h17.561' transform='translate(277.582 3220.55) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='1.5'/%3E%3Cg id='Ellipse_1099' data-name='Ellipse 1099' transform='translate(570.862 293.302)' fill='none' stroke='%23000' stroke-width='1.5'%3E%3Ccircle cx='13' cy='13' r='13' stroke='none'/%3E%3Ccircle cx='13' cy='13' r='12.25' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }
            .image-side {
                width: 312px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .popup-form-wrapper {
                .popup-form {
                    height: 100%;
                }
                width: calc(100% - 312px);
                padding: 1.5rem;
                margin-top: 3rem;
                .form-header {
                    text-align: center;
                    .title {
                        font-size: 2.6rem;
                        font-weight: 700;
                        color: #00BAC6;
                    }
                    .description {
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: #000;
                    }
                    .lcy {
                        margin-top: 3rem;
                        font-size: 2rem;
                        font-weight: 700;
                        color: #00BAC6;
                    }
                }
                .form-group:not(.submit-btn) {
                    height: 40px;
                    background: #f8f8f8;
                    border-radius: 5px;
                    position: relative;
                    input {
                        height: 100%;
                        border-radius: 0;
                        border: none;
                        box-shadow: none;
                        background: transparent;
                        color: #5f5f5f;
                        &:not(#phone:lang(en)){
                            padding-inline-start: 40px;
                        }
                    }
                    &.name::after {
                        content: "\f007";
                        font-family: "Font Awesome 5 Pro";
                        color: #5f5f5f;
                        position: absolute;
                        inset-inline-start: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.phone:lang(ar)::after {
                        content:"\f3cd";
                        font-family: "Font Awesome 5 Free";
                        color: #5f5f5f;
                        font-weight: 900;
                        position: absolute;
                        inset-inline-start: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .intl-tel-input {
                        direction: ltr !important;
                        height: 100%;
                        input:lang(en){
                            direction: ltr;
                        }
                        input:lang(ar){
                            direction: rtl;
                        }
                    }
                }
                .submit-btn {
                    height: 65px;
                    button {
                        background: #00BAC6;
                        color: #fff;
                        font-weight: 300;
                        padding: 0;
                        width: 100%;
                        height: 40px;
                    }
                    .free-consultation {
                        font-size: 13px;
                        color: #484848;
                    }
                }
                .whatsapp-link {
                    text-align: center;
                    padding-top: 2rem;
                    .whatsapp-animation-icon {
                        background: #25d366;
                        color: #fff;
                        border-radius: 50%;
                        position: relative;
                        z-index: 1;
                        animation: shickshake 1s infinite;
                        display: inline-block;
                        i {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 23px;
                            border-radius: 50%;
                            text-align: center;
                            padding: 0;
                        }
                        &:hover {
                            background: rgb(37, 211, 102);
                            color: #fff;
                        }
                        @keyframes shickshake {
                            0% {
                                transform: rotate(0deg);
                                box-shadow: 0 0 0 0 rgba(37, 211, 101, 0.6);
                            }
                            10% {
                                transform: rotate(5deg);
                            }
                            20% {
                                transform: rotate(0deg);
                            }
                            30% {
                                transform: rotate(-5deg);
                            }
                            40% {
                                transform: rotate(0deg);
                            }
                            50% {
                                transform: rotate(5deg);
                            }
                            60% {
                                transform: rotate(0deg);
                            }
                            70% {
                                transform: rotate(-5deg);
                                box-shadow: 0 0 0 10px rgba(37, 211, 102, 0);
                            }
                            100% {
                                transform: rotate(0deg);
                                box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:991px) {
        .modal-dialog{
            .popup-modal{
                width: auto;
                margin: 15px !important;
                .image-side{
                    display: none;
                }
                .popup-form-wrapper{
                    width: 100%;
                }
            }
        }
    }
}
